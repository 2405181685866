import React from "react";
import styles from "./BlockchainsAndCurrencies.module.scss";
import iconAvalanche from "../../assets/iconBlockchainsAndCurrencies/iconAvalanche.png";
import iconBNB from "../../assets/iconBlockchainsAndCurrencies/iconBNB.png";
import iconBTC from "../../assets/iconBlockchainsAndCurrencies/iconBTC.png";
import iconBUSD from "../../assets/iconBlockchainsAndCurrencies/iconBUSD.png";
import iconDogechain from "../../assets/iconBlockchainsAndCurrencies/iconDogechain.png";
import iconEth from "../../assets/iconBlockchainsAndCurrencies/iconEth.png";
import iconMoonbeam from "../../assets/iconBlockchainsAndCurrencies/iconMoonbeam.png";
import iconNEAR from "../../assets/iconBlockchainsAndCurrencies/iconNEAR.png";
import iconOasis from "../../assets/iconBlockchainsAndCurrencies/iconOasis.png";
import iconRonin from "../../assets/iconBlockchainsAndCurrencies/iconRonin.png";
import iconSkale from "../../assets/iconBlockchainsAndCurrencies/iconSkale.png";
import iconSolana from "../../assets/iconBlockchainsAndCurrencies/iconSolana.png";
import iconUSDC from "../../assets/iconBlockchainsAndCurrencies/iconUSDC.png";
import iconUSDT from "../../assets/iconBlockchainsAndCurrencies/iconUSDT.png";
import iconVelas from "../../assets/iconBlockchainsAndCurrencies/iconVelas.png";
import warning from "../../assets/common/warning.png";


export const BlockchainsAndCurrencies: React.FC = () => {

	return (
		<div className={styles.content}>
			<div className={styles.currencies}>
				<div className={styles.title}>
					Currencies<br /> we have<br /> integrated
				</div>
				<div className={styles.currency}>
					<img src={iconBNB} alt={''} />
					<div className={styles.nameCyrrency}>BNB</div>
				</div>
				<div className={styles.currency}>
					<img src={iconEth} alt={''} />
					<div className={styles.nameCyrrency}>ETH</div>
				</div>
				<div className={styles.currency}>
					<img src={iconUSDT} alt={''} />
					<div className={styles.nameCyrrency}>USDT</div>
				</div>
				<div className={styles.currency}>
					<img src={iconUSDC} alt={''} />
					<div className={styles.nameCyrrency}>USDC</div>
				</div>
				<div className={styles.currency}>
					<img src={iconBUSD} alt={''} />
					<div className={styles.nameCyrrency}>BUSD</div>
				</div>
				<div className={styles.currency}>
					<img src={iconVelas} alt={''} />
					<div className={styles.nameCyrrency}>VLX</div>
				</div>
				<div className={styles.currency}>
					<img src={iconSkale} alt={''} />
					<div className={styles.nameCyrrency}>SKL</div>
				</div>
				<div className={styles.currency}>
					<img src={iconBTC} alt={''} />
					<div className={styles.nameCyrrency}>BTC</div>
				</div>
				<div className={styles.currency}>
					<img src={iconOasis} alt={''} />
					<div className={styles.nameCyrrency}>ROSE</div>
				</div>
				<div className={styles.other}>
					+6 Others
				</div>
			</div>
			<div className={styles.blockchains}>
				<div className={styles.titleBlockchains}>
					Blockchains<br/> we have<br/> built on
				</div>
				<div className={styles.currency}>
					<img src={iconNEAR} alt={''} />
					<div className={styles.nameCyrrency}>NEAR</div>
				</div>
				<div className={styles.currency}>
					<img src={iconSolana} alt={''} />
					<div className={styles.nameCyrrency}>Solana</div>
				</div>
				<div className={styles.currency}>
					<img src={iconAvalanche} alt={''} />
					<div className={styles.nameCyrrency}>Avalanche</div>
				</div>
				<div className={styles.currency}>
					<img src={iconVelas} alt={''} />
					<div className={styles.nameCyrrency}>Velas</div>
				</div>
				<div className={styles.currency}>
					<img src={iconSkale} alt={''} />
					<div className={styles.nameCyrrency}>Skale</div>
				</div>
				<div className={styles.currency}>
					<img src={iconRonin} alt={''} />
					<div className={styles.nameCyrrency}>Ronin</div>
				</div>
				<div className={styles.currency}>
					<img src={iconMoonbeam} alt={''} />
					<div className={styles.nameCyrrency}>Moonbeam</div>
				</div>
				<div className={styles.currency}>
					<img src={iconOasis} alt={''} />
					<div className={styles.nameCyrrency}>Oasis</div>
				</div>
				<div className={styles.currency}>
					<img src={iconDogechain} alt={''} />
					<div className={styles.nameCyrrency}>Dogechain</div>
				</div>
				<div className={styles.other}>
					+4 <span>Others</span>
				</div>
			</div>
			<div className={styles.warning}>
				<img src={warning} alt={''}/>
				<div>These are just some recent examples. We work with any<br/> currencies and blockchains.</div>
			</div>
		</div>
	);
};







