import React, { useEffect } from "react";
import { AnimatePresence, motion } from "framer-motion";
import workFour from "../../../pagesWorks/workFour.png";

export const EverscaleAcademyCase: React.FC = () => {
	useEffect(() => {
		const newImage = new Image();
		newImage.src = workFour;
	}, []);

	return (
		<AnimatePresence>
			<motion.div
				initial={{ x: -2000 }}
				animate={{ x: 0 }}
				exit={{ x: -2000 }}
				className={`page_work`}>
				<div className={'intend'}></div>
				<img src={workFour} alt={''} className="page_work_img" />
			</motion.div>
		</AnimatePresence>
	);
};







