import React, { useEffect } from "react";
import { AnimatePresence, motion } from "framer-motion";
import bridge from "../../../pagesWorks/Tezos.png";

export const BlockchainBridgeTezos: React.FC = () => {
	useEffect(() => {
		const newImage = new Image();
		newImage.src = bridge;
	}, []);

	return (
		<AnimatePresence>
			<motion.div
				initial={{ x: -2000 }}
				animate={{ x: 0 }}
				exit={{ x: -2000 }}
				className={`page_work`}
			>
				<div className={"intend"}></div>
				<img src={bridge} alt={""} className="page_work_img" />
			</motion.div>
		</AnimatePresence>
	);
};
