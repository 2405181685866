import Braket from "../../../pagesWorks/Braket.png";
import React, { useEffect } from "react";
import { AnimatePresence, motion } from "framer-motion";

export const BraketCase: React.FC = () => {
	useEffect(() => {
		const newImage = new Image();
		newImage.src = Braket;
	}, []);

	return (
		<AnimatePresence>
			<motion.div
				initial={{ x: -2000 }}
				animate={{ x: 0 }}
				exit={{ x: -2000 }}
				// transition={{ duration: 0.3 }}
				className={`page_work`}
			>
				<div className={'intend'}></div>
				<img className={`page_work_img`} src={Braket} alt="" />
			</motion.div>
		</AnimatePresence>
	);
};







