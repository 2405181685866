import { Route, Routes } from "react-router-dom";
import Main from "./Main";
import { PagePixudiCase } from "./components/Cases/PagePixudiCase/PagePixudiCase";
import { Header } from "./components/Header/Header";
import { PageThemoonCase } from "./components/Cases/PageThemoonCase/PageThemoonCase";
import { PortofolioCase } from "./components/Cases/PortofolioCase/PortofolioCase";
import { TheMoonCase } from "./components/Cases/TheMoonCase/TheMoonCase";
import { EverscaleAcademyCase } from "./components/Cases/EverscaleAcademyCase/EverscaleAcademyCase";
import { ImaraWorkCase } from "./components/Cases/ImaraWorkCase/ImaraWorkCase";
import { BlockchainBridgeTezos } from "./components/Cases/BlockchainBridgeTezos/BlockchainBridgeTezos";
import { PixieCase } from "./components/Cases/PixieCase/PixieCase";
import { BraketCase } from "./components/Cases/BraketCase/BraketCase";
import { Projects } from "./pages/Projects/Projects";
import { Portfolio } from "./pages/Portfolio/Portfolio";

export function App() {
	return (
		<Routes>
			<Route path="/" element={<Main />} />
			<Route path="/projects" element={<Projects />} />
			<Route path="/portfolio" element={<Portfolio />} />
			<Route
				path="/case_pixudi"
				element={
					<>
						<div className="center_header">
							<Header />
						</div>
						<PagePixudiCase />
					</>
				}
			/>
			<Route
				path="/case_themoon"
				element={
					<>
						<div className="center_header">
							<Header />
						</div>
						<PageThemoonCase />
					</>
				}
			/>
			<Route
				path="/case_portofolio"
				element={
					<>
						<div className="center_header">
							<Header />
						</div>
						<PortofolioCase />
					</>
				}
			/>
			<Route
				path="/themoon"
				element={
					<>
						<div className="center_header">
							<Header />
						</div>
						<TheMoonCase />
					</>
				}
			/>
			<Route
				path="/everscale-academy"
				element={
					<>
						<div className="center_header">
							<Header />
						</div>
						<EverscaleAcademyCase />
					</>
				}
			/>
			<Route
				path="/imara"
				element={
					<>
						<div className="center_header">
							<Header />
						</div>
						<ImaraWorkCase />
					</>
				}
			/>
			<Route
				path="/blockchain-bridge-tezos"
				element={
					<>
						<div className="center_header">
							<Header />
						</div>
						<BlockchainBridgeTezos />
					</>
				}
			/>
			<Route
				path="/pixie"
				element={
					<>
						<div className="center_header">
							<Header />
						</div>
						<PixieCase />
					</>
				}
			/>
			<Route
				path="/braket"
				element={
					<>
						<div className="center_header">
							<Header />
						</div>
						<BraketCase />
					</>
				}
			/>
		</Routes>
	);
}
