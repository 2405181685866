import React, { useEffect, useRef, useState } from "react";
import head_fon from "./assets/head_fon.mp4";
import "./App.css";
import Swiper from "react-id-swiper";
import "swiper/css/swiper.css";
import trotsky from "./pagesWorks/pagePortfolio/trotsky.png";
import parvis from "./pagesWorks/pagePortfolio/parvis.png";
import deform from "./pagesWorks/pagePortfolio/deform.png";
import webdrasil from "./pagesWorks/pagePortfolio/webdrasil.png";
import mami from "./pagesWorks/pagePortfolio/mami.png";
import momuro from "./pagesWorks/pagePortfolio/momuro.png";
import {
	arrowL,
	arrowR,
	border_btn_contact_us,
	border_btn_contact_us_active,
	border_btn_slaid,
	border_btn_slaid_active,
	btn_works_border,
	get_in_touch,
	head_arrow_bottom,
	head_submain_text,
	icon_git_hub,
	icon_opp_block2,
	icon_opp_blockchain,
	icon_opp_game,
	icon_opp_landing,
	icon_opp_nft,
	icon_opp_serv,
	icon_opp_smart,
	icon_opp_techn,
	icon_telegram,
	left_opp_letters,
	line_gorizont,
	opportunities_line,
	star_input,
	text_after_opp,
	text_after_opp_mobile,
	text_after_works,
} from "./assets/image";
import { send } from "emailjs-com";
import "react-toastify/dist/ReactToastify.css";
import { AnimatePresence, motion } from "framer-motion";
import { toast, ToastContainer } from "react-toastify";
import { Header } from "./components/Header/Header";
import { BlockchainsAndCurrencies } from "./components/BlockchainsAndCurrencies/BlockchainsAndCurrencies";
import caseStudyPixudi from "./pagesWorks/pagePortfolio/caseStudyPixudi.png";
import { useNavigate } from "react-router-dom";

function Main() {
	const [isClickWebdrasil, setisClickWebdrasil] = useState(false);
	const [isClickMamiWork, setisClickMamiWork] = useState(false);
	const [isClickDeformWork, setisClickDeformWork] = useState(false);
	const [isClickPixudiWork, setisClickPixudiWork] = useState(false);
	const [isClickMomuroWork, setisClickMomuroWork] = useState(false);
	const [isClickParvisWork, setisClickParvisWork] = useState(false);
	const [isClickTrotskyWork, setisClickTrotskyWork] = useState(false);
	const RefPortfolio = useRef<any>(true);
	const RefAbout = useRef<any>(true);
	const RefSkills = useRef<any>(true);
	const RefHireUs = useRef<any>(true);
	const swiperRef = useRef<any>(null);
	const swiperWorksRef = useRef<any>(null);
	const navigate = useNavigate();
	const scrollToRef = (ref: any) =>
		window.scrollTo({ behavior: "smooth", top: ref.current.offsetTop });

	const goNext = () => {
		if (swiperRef.current && swiperRef.current.swiper) {
			swiperRef.current.swiper.slideNext();
		}
	};
	const goPrev = () => {
		if (swiperRef.current && swiperRef.current.swiper) {
			swiperRef.current.swiper.slidePrev();
		}
	};
	const goNextWork = () => {
		if (swiperWorksRef.current && swiperWorksRef.current.swiper) {
			swiperWorksRef.current.swiper.slideNext();
		}
	};
	const goPrevWork = () => {
		if (swiperWorksRef.current && swiperWorksRef.current.swiper) {
			swiperWorksRef.current.swiper.slidePrev();
		}
	};

	const params = {
		pagination: {
			el: ".swiper-pagination",
			clickable: true,
			// dynamicBullets: true,
		},
		loop: true,
		slidesPerView: 1,
		spaceBetween: 30,
		breakpoints: {
			700: {
				slidesPerView: 2,
			},
		},
	};
	const [toSend, setToSend] = useState({
		name: "",
		email: "",
		telegram: "",
		otherMessenger: "",
		comment: "",
	});
	const handleChange = (e: any) => {
		setToSend({ ...toSend, [e.target.name]: e.target.value });
	};
	const submitForm = () => {
		send("service_lltc4os", "template_vo1qtd4", toSend, "d6RF_xYbYTsh5a2wy")
			.then((response) => {
				console.log("SUCCESS!", response.status, response.text);
				toast.success(
					"Your message has been sent. We will contact you as soon as possible!"
				);
			})
			.catch((err) => {
				console.log("FAILED...", err);
				toast.error("Something went wrong");
			});
	};
	const paramsWorsk = {
		pagination: {
			el: ".swiper-pagination",
			clickable: true,
		},
		loop: true,
	};

	return (
		<>
			<div className="App">
				<AnimatePresence>
					<motion.div
						initial={{ opacity: 0 }}
						animate={{ opacity: 1 }}
						exit={{ opacity: 0 }}
						transition={{ duration: 0.5 }}
						className="App_content"
					>
						<ToastContainer position="top-center" />
						<div className="wrapper_center">
							<Header />
							<video
								className="video_head"
								autoPlay
								loop
								muted
								playsInline
								controls={false}
							>
								<source src={head_fon} type="video/webm" />
							</video>
							<div className="head_three_layer"></div>
						</div>
						<div>
							<div className="wrapper_center">
								<div className="head">
									<div className="head__title">
										<div className="head__main_text">
											We design & develop digital products
										</div>
										<div className="head__submain_text">
											{head_submain_text}
										</div>
										<div className="btn_head_main_mobile">
											<div className="btn_сontact_us ">
												<div
													className="btn_сontact_us__text"
													onClick={() => {
														scrollToRef(RefHireUs);
													}}
												>
													Hire us
												</div>
												<div className="btn_сontact_us__fill">
													{border_btn_contact_us_active}
												</div>
												<div className="btn_сontact_us__border">
													{border_btn_contact_us}
												</div>
											</div>
										</div>
									</div>
									<div className="head__bottom">
										<button className="head__bottom-left-btn">
											Get in touch
										</button>
										<button className="head__bottom-left-btn">
											{head_arrow_bottom}
										</button>
										<div className="icon_social_networks">
											<div
												className="icon_git_hub"
												onClick={() => {
													window.open("https://github.com/kapcrew", "_blank");
												}}
											>
												{icon_git_hub}
											</div>
											<div
												className="icon_telegram"
												onClick={() => {
													window.open("https://t.me/ducktalesblock", "_blank");
												}}
											>
												{icon_telegram}
											</div>
										</div>
									</div>
								</div>
							</div>

							<div className="center_content">
								<div className="wrapper_maxWidth">
									<div className="opportunities" ref={RefAbout} id={"about"}>
										<div className="left_opp_letters">{left_opp_letters}</div>
										<div className="opportunities__text">
											<div className="opportunities__main_text">
												We will take your business to the new level
											</div>

											<div className="opportunities__submain_text">
												Kapcrew is a team of dedicated developers, designers and
												managers. We provide our customers with high-quality
												dapps, websites, marketplaces, custom web applications,
												blockchain &NFT consulting and design services. .{" "}
											</div>
											<div className="opportunities__submain_text">
												Our team is effectively managed by a minimal number of
												people which allows us to deliver products much faster
												than large companies yet with the same quality. At the
												same time, our agile management system gives us endless
												scalability.
											</div>
											<div className="opportunities__submain_text">
												We will help you enhance your services by implementing
												custom solutions made for your business.
											</div>
										</div>
										<div className="opportunities_line">
											{opportunities_line}
										</div>
										<div className="opportunities_line_gor">
											{line_gorizont}
										</div>

										<div className="opportunities__blocks">
											<button
												className="arrow_btn_next_slaid "
												onClick={goNext}
											>
												<div className="arrow_btn_next_slaid_arrow">
													{arrowR}
												</div>
												<div className="arrow_btn_next_slaid_border">
													{border_btn_slaid}
												</div>
												<div className="border_btn_slaid_active">
													{border_btn_slaid_active}
												</div>
											</button>

											<Swiper
												ref={swiperRef}
												{...params}
												containerClass={"opportunities_swiper swiper-container"}
											>
												<div className="opportunities__two_blocks">
													<div className="opportunities__block ">
														<div className="opportunities__block_icon">
															{icon_opp_blockchain}
														</div>
														<div className="opportunities__block_title">
															<svg
																width={"125px"}
																height={"25px"}
																className="opportunities__block_title__svg title_blockchain_apps"
																xmlns="http://www.w3.org/2000/svg"
																version="1.1"
															>
																<defs>
																	<linearGradient
																		id="grad1"
																		x1="0%"
																		y1="0%"
																		x2="0%"
																		y2="100%"
																	>
																		<stop
																			offset="0%"
																			style={{
																				stopColor: "#0073fa",
																				stopOpacity: "1",
																			}}
																		/>
																		<stop
																			offset="100%"
																			style={{
																				stopColor: "#00feef",
																				stopOpacity: "1",
																			}}
																		/>
																	</linearGradient>
																</defs>
																<text fill="url(#grad1)" y="10" x="2">
																	Blockchain apps
																</text>
																<text fill="url(#grad1)" y="25">
																	developmenteee
																</text>
															</svg>
														</div>
														<div className="opportunities__block_text">
															We build backend, frontend, WebUI projects based
															on blockchain technologies
														</div>
													</div>
													<div className="opportunities__block">
														<div className="opportunities__block_icon">
															{icon_opp_smart}
														</div>
														<div className="opportunities__block_title">
															<svg
																width={"125px"}
																height={"15px"}
																className="opportunities__block_title__svg title_smart_contracts"
																xmlns="http://www.w3.org/2000/svg"
																version="1.1"
															>
																<defs>
																	<linearGradient
																		id="grad1"
																		x1="0%"
																		y1="0%"
																		x2="0%"
																		y2="100%"
																	>
																		<stop
																			offset="0%"
																			style={{
																				stopColor: "#0073fa",
																				stopOpacity: "1",
																			}}
																		/>
																		<stop
																			offset="100%"
																			style={{
																				stopColor: "#00feef",
																				stopOpacity: "1",
																			}}
																		/>
																	</linearGradient>
																</defs>
																<text fill="url(#grad1)" y="10">
																	Smart contracts
																</text>
															</svg>
														</div>
														<div className="opportunities__block_text">
															We write and test all types of smart contracts
														</div>
													</div>
												</div>

												<div className="opportunities__two_blocks">
													<div className="opportunities__block non_block ">
														<div className="opportunities__block_icon">
															{icon_opp_techn}
														</div>
														<div className="opportunities__block_title">
															<svg
																width={"145px"}
																height={"35px"}
																className="opportunities__block_title__svg"
																xmlns="http://www.w3.org/2000/svg"
																version="1.1"
															>
																<defs>
																	<linearGradient
																		id="grad1"
																		x1="0%"
																		y1="0%"
																		x2="0%"
																		y2="100%"
																	>
																		<stop
																			offset="0%"
																			style={{
																				stopColor: "#0073fa",
																				stopOpacity: "1",
																			}}
																		/>
																		<stop
																			offset="100%"
																			style={{
																				stopColor: "#00feef",
																				stopOpacity: "1",
																			}}
																		/>
																	</linearGradient>
																</defs>
																<text fill="url(#grad1)" y="10">
																	Technical development
																</text>
																<text fill="url(#grad1)" y="25" x="55px">
																	for ICO
																</text>
															</svg>
														</div>
														<div className="opportunities__block_text technical_block_mobile">
															If you have a business idea and want to prepare a
															platform for ICO, we can help you build
															architecture, launch a token, etc.
														</div>
														<div className="opportunities__block_text technical_block">
															If you have a business idea and want to prepare a
															platform for ICO, we can do it for you. We will
															create a landing page, develop a token, build
															architecture, etc.
														</div>
													</div>
													<div className="opportunities__block non_block">
														<div className="opportunities__block_icon">
															{icon_opp_nft}
														</div>
														<div className="opportunities__block_title">
															<svg
																width={"135px"}
																height={"15px"}
																className="opportunities__block_title__svg title_marketplaces"
																xmlns="http://www.w3.org/2000/svg"
																version="1.1"
															>
																<defs>
																	<linearGradient
																		id="grad1"
																		x1="0%"
																		y1="0%"
																		x2="0%"
																		y2="100%"
																	>
																		<stop
																			offset="0%"
																			style={{
																				stopColor: "#0073fa",
																				stopOpacity: "1",
																			}}
																		/>
																		<stop
																			offset="100%"
																			style={{
																				stopColor: "#00feef",
																				stopOpacity: "1",
																			}}
																		/>
																	</linearGradient>
																</defs>
																<text fill="url(#grad1)" y="10">
																	NFT Marketplaces
																</text>
															</svg>
														</div>
														<div className="opportunities__block_text">
															We will build a fully functioning NFT marketplace
															on any blockchain you chooses
														</div>
													</div>
												</div>

												<div className="opportunities__two_blocks">
													<div className="opportunities__block ">
														<div className="opportunities__block_icon">
															{icon_opp_serv}
														</div>
														<div className="opportunities__block_title">
															<svg
																width={"135px"}
																height={"15px"}
																className="opportunities__block_title__svg title_service"
																xmlns="http://www.w3.org/2000/svg"
																version="1.1"
															>
																<defs>
																	<linearGradient
																		id="grad1"
																		x1="0%"
																		y1="0%"
																		x2="0%"
																		y2="100%"
																	>
																		<stop
																			offset="0%"
																			style={{
																				stopColor: "#0073fa",
																				stopOpacity: "1",
																			}}
																		/>
																		<stop
																			offset="100%"
																			style={{
																				stopColor: "#00feef",
																				stopOpacity: "1",
																			}}
																		/>
																	</linearGradient>
																</defs>
																<text fill="url(#grad1)" y="10">
																	Service platforms
																</text>
															</svg>
														</div>
														<div className="opportunities__block_text">
															We can help you with design, architecture,
															frontend and backend for any kind of a website/
															web service platform;
														</div>
													</div>
													<div className="opportunities__block">
														<div className="opportunities__block_icon">
															{icon_opp_block2}
														</div>
														<div className="opportunities__block_title">
															<svg
																width={"165px"}
																height={"15px"}
																className="opportunities__block_title__svg title_blockchain_integration"
																xmlns="http://www.w3.org/2000/svg"
																version="1.1"
															>
																<defs>
																	<linearGradient
																		id="grad1"
																		x1="0%"
																		y1="0%"
																		x2="0%"
																		y2="100%"
																	>
																		<stop
																			offset="0%"
																			style={{
																				stopColor: "#0073fa",
																				stopOpacity: "1",
																			}}
																		/>
																		<stop
																			offset="100%"
																			style={{
																				stopColor: "#00feef",
																				stopOpacity: "1",
																			}}
																		/>
																	</linearGradient>
																</defs>
																<text fill="url(#grad1)" y="10">
																	Blockchain Integration
																</text>
															</svg>
														</div>
														<div className="opportunities__block_text">
															We can integrate any blockchain into your product
														</div>
													</div>
												</div>

												<div className="opportunities__two_blocks">
													<div className="opportunities__block ">
														<div className="opportunities__block_icon">
															{icon_opp_landing}
														</div>
														<div className="opportunities__block_title">
															<svg
																width={"110px"}
																height={"15px"}
																className="opportunities__block_title__svg title_landing"
																xmlns="http://www.w3.org/2000/svg"
																version="1.1"
															>
																<defs>
																	<linearGradient
																		id="grad1"
																		x1="0%"
																		y1="0%"
																		x2="0%"
																		y2="100%"
																	>
																		<stop
																			offset="0%"
																			style={{
																				stopColor: "#0073fa",
																				stopOpacity: "1",
																			}}
																		/>
																		<stop
																			offset="100%"
																			style={{
																				stopColor: "#00feef",
																				stopOpacity: "1",
																			}}
																		/>
																	</linearGradient>
																</defs>
																<text fill="url(#grad1)" y="10">
																	Landing pages
																</text>
															</svg>
														</div>
														<div className="opportunities__block_text">
															An effective landing page is your key to success.
															We will help you build it.
														</div>
													</div>
													<div className="opportunities__block">
														<div className="opportunities__block_icon">
															{icon_opp_game}
														</div>
														<div className="opportunities__block_title">
															<svg
																width={"185px"}
																height={"15px"}
																className="opportunities__block_title__svg title_browser_games"
																xmlns="http://www.w3.org/2000/svg"
																version="1.1"
															>
																<defs>
																	<linearGradient
																		id="grad1"
																		x1="0%"
																		y1="0%"
																		x2="0%"
																		y2="100%"
																	>
																		<stop
																			offset="0%"
																			style={{
																				stopColor: "#0073fa",
																				stopOpacity: "1",
																			}}
																		/>
																		<stop
																			offset="100%"
																			style={{
																				stopColor: "#00feef",
																				stopOpacity: "1",
																			}}
																		/>
																	</linearGradient>
																</defs>
																<text fill="url(#grad1)" y="10">
																	Browser games / GameFi
																</text>
															</svg>
														</div>
														<div className="opportunities__block_text">
															We love developing browser games (especially those
															built on blockchain) and have all resources for it
														</div>
													</div>
												</div>
											</Swiper>
										</div>
									</div>

									<BlockchainsAndCurrencies />

									<div
										className="text_acquainted"
										id="portfolio"
										onClick={() => navigate("/projects")}
									>
										{text_after_opp}
									</div>
									<div className="text_acquainted_mobile">
										{text_after_opp_mobile}
									</div>
									<div className="for_slider_works" ref={RefPortfolio}>
										<div className="slider_works">
											<button
												className="arrow_btn_prev_slaid  btn_works_prev"
												onClick={goPrevWork}
											>
												<div className="arrow_btn_prev_slaid_arrow">
													{arrowL}
												</div>
												<div className="arrow_btn_next_slaid_border">
													{border_btn_slaid}
												</div>
												<div className="border_btn_slaid_active">
													{border_btn_slaid_active}
												</div>
											</button>
											<button
												className="arrow_btn_next_slaid btn_works btn_works_next"
												onClick={goNextWork}
											>
												<div className="arrow_btn_next_slaid_arrow">
													{arrowR}
												</div>
												<div className="arrow_btn_next_slaid_border">
													{border_btn_slaid}
												</div>
												<div className="border_btn_slaid_active">
													{border_btn_slaid_active}
												</div>
											</button>
											<Swiper ref={swiperWorksRef} {...paramsWorsk}>
												<div
													className="slider_works__slide"
													onClick={() => {
														setisClickPixudiWork(!isClickPixudiWork);
													}}
												>
													<img
														className="slider_works__img"
														src={caseStudyPixudi}
														alt=""
													/>
													<AnimatePresence>
														{isClickPixudiWork && (
															<motion.div
																initial={{ opacity: 0 }}
																animate={{ opacity: 1 }}
																exit={{ opacity: 0 }}
																transition={{ duration: 0.5 }}
																className="slider_works__img_active"
																onClick={() => {
																	setisClickPixudiWork(false);
																}}
															>
																<div className="active_slider_work">
																	Play2Collect board game
																	<br /> built on blockchain <br />
																	July 2022 - August 2022
																</div>
																<button
																	onClick={() => {
																		window.open("/#/case_pixudi");
																	}}
																	className="active_slider_work__btn"
																>
																	<div className="active_slider_work__btn_border">
																		{btn_works_border}
																	</div>
																	Click to see more
																</button>
															</motion.div>
														)}
													</AnimatePresence>
												</div>
												<div
													className="slider_works__slide"
													id={"slider_works"}
													onClick={() => {
														setisClickMomuroWork(!isClickMomuroWork);
													}}
												>
													<img
														className="slider_works__img"
														src={momuro}
														alt=""
													/>
													<AnimatePresence>
														{isClickMomuroWork && (
															<motion.div
																initial={{ opacity: 0 }}
																animate={{ opacity: 1 }}
																exit={{ opacity: 0 }}
																transition={{ duration: 0.5 }}
																className="slider_works__img_active"
																onClick={() => {
																	setisClickMomuroWork(false);
																}}
															>
																{/* <div className="active_slider_work">
																			Pixie NFT Metaverse
																			<br />
																			Landing Page
																			<br />
																			October 2022
																		</div> */}
																<button
																	onClick={() => {
																		window.open("https://momuro.quest");
																	}}
																	className="active_slider_work__btn"
																>
																	<div className="active_slider_work__btn_border">
																		{btn_works_border}
																	</div>
																	Click to see more
																</button>
															</motion.div>
														)}
													</AnimatePresence>
												</div>
												<div
													className="slider_works__slide"
													id={"slider_works"}
													onClick={() => {
														setisClickParvisWork(!isClickParvisWork);
													}}
												>
													<img
														className="slider_works__img"
														src={parvis}
														alt=""
													/>
													<AnimatePresence>
														{isClickParvisWork && (
															<motion.div
																initial={{ opacity: 0 }}
																animate={{ opacity: 1 }}
																exit={{ opacity: 0 }}
																transition={{ duration: 0.5 }}
																className="slider_works__img_active"
																onClick={() => {
																	setisClickParvisWork(false);
																}}
															>
																<div className="active_slider_work">
																	Blockchiain-based social network
																	<br />
																	Landing and layout prototype
																	<br />
																	Summer ‘23
																</div>
																<button
																	onClick={() => {
																		window.open("https://parvis.network/");
																	}}
																	className="active_slider_work__btn"
																>
																	<div className="active_slider_work__btn_border">
																		{btn_works_border}
																	</div>
																	Click to see more
																</button>
															</motion.div>
														)}
													</AnimatePresence>
												</div>
												<div
													className="slider_works__slide"
													onClick={() => {
														setisClickTrotskyWork(!isClickTrotskyWork);
													}}
												>
													<img
														className="slider_works__img"
														src={trotsky}
														alt=""
													/>
													<AnimatePresence>
														{isClickTrotskyWork && (
															<motion.div
																initial={{ opacity: 0 }}
																animate={{ opacity: 1 }}
																exit={{ opacity: 0 }}
																transition={{ duration: 0.5 }}
																className="slider_works__img_active"
																onClick={() => {
																	setisClickTrotskyWork(false);
																}}
															>
																<div className="active_slider_work">
																	Restaurant in Mexico <br /> Landing page{" "}
																	<br />
																	Summer ‘23
																</div>
																<button
																	onClick={() => {
																		window.open("https://trotskyplaya.com/");
																	}}
																	className="active_slider_work__btn"
																>
																	<div className="active_slider_work__btn_border">
																		{btn_works_border}
																	</div>
																	Click to see more
																</button>
															</motion.div>
														)}
													</AnimatePresence>
												</div>
												<div
													className="slider_works__slide"
													onClick={() => {
														setisClickMamiWork(!isClickMamiWork);
													}}
												>
													<img
														className="slider_works__img"
														src={mami}
														alt=""
													/>
													<AnimatePresence>
														{isClickMamiWork && (
															<motion.div
																initial={{ opacity: 0 }}
																animate={{ opacity: 1 }}
																exit={{ opacity: 0 }}
																transition={{ duration: 0.5 }}
																className="slider_works__img_active"
																onClick={() => {
																	setisClickMamiWork(false);
																}}
															>
																<div className="active_slider_work">
																	AI-driven meal planning / cooking app <br />{" "}
																	Under development
																</div>
																<button
																	onClick={() => {
																		window.open("https://mami.today/");
																	}}
																	className="active_slider_work__btn"
																>
																	<div className="active_slider_work__btn_border">
																		{btn_works_border}
																	</div>
																	Click to see more
																</button>
															</motion.div>
														)}
													</AnimatePresence>
												</div>
												<div
													className="slider_works__slide"
													onClick={() => {
														setisClickWebdrasil(!isClickWebdrasil);
													}}
												>
													<img
														className="slider_works__img"
														src={webdrasil}
														alt=""
													/>
													<AnimatePresence>
														{isClickWebdrasil && (
															<motion.div
																initial={{ opacity: 0 }}
																animate={{ opacity: 1 }}
																exit={{ opacity: 0 }}
																transition={{ duration: 0.5 }}
																className="slider_works__img_active"
																onClick={() => {
																	setisClickWebdrasil(false);
																}}
															>
																<div className="active_slider_work">
																	WEB 2.5 foundation <br /> Under Development
																</div>
																<button
																	onClick={() => {
																		window.open("https://webdrasill.com/");
																	}}
																	className="active_slider_work__btn"
																>
																	<div className="active_slider_work__btn_border">
																		{btn_works_border}
																	</div>
																	Click to see more
																</button>
															</motion.div>
														)}
													</AnimatePresence>
												</div>
												<div
													className="slider_works__slide"
													onClick={() => {
														setisClickDeformWork(!isClickDeformWork);
													}}
												>
													<img
														className="slider_works__img"
														src={deform}
														alt=""
													/>
													<AnimatePresence>
														{isClickDeformWork && (
															<motion.div
																initial={{ opacity: 0 }}
																animate={{ opacity: 1 }}
																exit={{ opacity: 0 }}
																transition={{ duration: 0.5 }}
																className="slider_works__img_active"
																onClick={() => {
																	setisClickDeformWork(false);
																}}
															>
																<div className="active_slider_work">
																	Text2Video AI tool
																	<br />
																	Working prototype + Landing Page
																	<br />
																	Summer - Autumn ‘23
																</div>
																<button
																	onClick={() => {
																		window.open("http://deform.ai/");
																	}}
																	className="active_slider_work__btn"
																>
																	<div className="active_slider_work__btn_border">
																		{btn_works_border}
																	</div>
																	Click to see more
																</button>
															</motion.div>
														)}
													</AnimatePresence>
												</div>
											</Swiper>
										</div>
									</div>
									<div
										className="toPortfolio"
										onClick={() => {
											navigate("/portfolio");
										}}
									>
										We also develop custom projects for other companies. Some of
										them can be viewed here.
									</div>
									<div className="text_stack" ref={RefSkills} id={"skills"}>
										{text_after_works}
									</div>
									<div></div>
									<div className="stack_block">
										<div className="stack_block__line_gorizont">
											<div className="stack_block_li_g"></div>
											<div className="stack_block_li_g"></div>
										</div>
										<div className="stack_block__line_ver">
											{" "}
											<div className="stack_block_li_v"></div>
										</div>
										<div className="text_stack__text_col">
											<div className="text_stack__text_row">
												<div className="text_stack__text_name">BACK-END</div>
												<div className="text_stack__text_value">
													C/C#/C++, Java Script, ASM, Solidity, Python, Shell,
													Node
												</div>
											</div>
											<div className="text_stack__text_row">
												<div className="text_stack__text_name">FRONTEND</div>
												<div className="text_stack__text_value">
													React JS, HTML, CSS, scss, Vue.js, Angular, Wordpress,
													Bootstrap, Redux, mobX
												</div>
											</div>
											<div className="text_stack__text_row">
												<div className="text_stack__text_name">DESIGN</div>
												<div className="text_stack__text_value">
													Figma, Photoshop, After Effects, Illustrator, Autodesk
													Maya
												</div>
											</div>
										</div>
									</div>
									<div
										className="title_form_connection"
										ref={RefHireUs}
										id={"hereUs"}
									>
										{get_in_touch}
									</div>
									<div className="block_form_connection">
										<div className="block_form_connection__image">
											{/* {mainPageHead} */}
										</div>
										<div className="form_connection">
											<div className="form_connection__inputs">
												<div className="block_input">
													<div className="name_input">
														Name <div className="star_input">{star_input}</div>
													</div>

													<input
														onChange={handleChange}
														type="text"
														className="form_connection__input"
														name="name"
													/>
												</div>
												<div className="block_input">
													<div className="name_input">
														Email
														<div className="star_input">{star_input}</div>
													</div>

													<input
														onChange={handleChange}
														type="text"
														className="form_connection__input"
														name="email"
													/>
												</div>
												<div className="block_input">
													<div className="name_input">Telegram</div>
													<input
														onChange={handleChange}
														type="text"
														className="form_connection__input"
														name="telegram"
													/>
												</div>
												<div className="block_input">
													<div className="name_input">Other messenger</div>
													<input
														onChange={handleChange}
														type="text"
														className="form_connection__input"
														name="otherMessenger"
													/>
												</div>
											</div>
											<div className="form_connection__textarea-block">
												<div className="name_input">
													Comment <div className="star_input">{star_input}</div>
												</div>

												<textarea
													className="form_connection__textarea"
													name="comment"
													onChange={handleChange}
												></textarea>
											</div>
											<div className="for_btn_сontact_us">
												<div className="btn_сontact_us" onClick={submitForm}>
													<div className="btn_сontact_us__text">Contact us</div>
													<div className="btn_сontact_us__fill">
														{border_btn_contact_us_active}
													</div>
													<div className="btn_сontact_us__border">
														{border_btn_contact_us}
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="title_form_connection"></div>
							</div>
						</div>
					</motion.div>
				</AnimatePresence>
			</div>
		</>
	);
}

export default Main;
