import React, { useRef, useState } from "react";
import {
	border_btn_contact_us,
	border_btn_contact_us_active,
	btn_hire_us,
	btn_hire_us_fill,
	close_menu_btn,
	icon_git_hub,
	icon_telegram,
	mainLogo,
	open_menu_btn,
} from "../../assets/image";
import { AnimatePresence, motion } from "framer-motion";
import { HashLink } from "react-router-hash-link";
import { useNavigate } from "react-router-dom";

export const Header: React.FC = () => {
	const navigate = useNavigate();
	const [isOpenMenu, setIsOpenMenu] = useState(false);
	const scrollToRef = (ref: any) =>
		window.scrollTo({ behavior: "smooth", top: ref.current.offsetTop });

	//todo fix link to sections in page
	return (
		<div className="header">
			<div className="main_logo" onClick={() => navigate("/")}>
				{mainLogo}
			</div>
			<div className="navbar">
				<HashLink className="navbar_btn" to={"/#about"} smooth>
					About
				</HashLink>
				<HashLink className="navbar_btn" to={"/#portfolio"} smooth>
					Portfolio
				</HashLink>
				<HashLink className="navbar_btn" to={"/#skills"} smooth>
					Skills
				</HashLink>
			</div>
			<div
				className="btn-open-menu"
				onClick={() => {
					setIsOpenMenu(true);
				}}
			>
				{open_menu_btn}
			</div>
			<AnimatePresence>
				{isOpenMenu && (
					<motion.div
						initial={{ opacity: 0 }}
						animate={{ opacity: 1 }}
						exit={{ opacity: 0 }}
						transition={{ duration: 0.5 }}
						className="menu"
					>
						<div className="menu__content">
							<div
								className="menu__btn-close-menu"
								onClick={() => {
									console.log("!!!!");
									setIsOpenMenu(false);
								}}
							>
								{close_menu_btn}
							</div>
							{/* <div className="for_btn_language">
								<button className={"btn_language"}>
									EN
									<svg
										width="7"
										height="4"
										viewBox="0 0 7 4"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M1 0L3.5 2.5L6 0L7 0.5L3.5 4L0 0.5L1 0Z"
											fill="white"
										/>
									</svg>
								</button>
								<div className="for_modal_language">
									<div className="modal_language">
										<button className="btn_language_choise">
											English
										</button>
										<button className="btn_language_choise">
											French
										</button>
									</div>
								</div>
							</div> */}
							<div className="navbar_menu">
								<HashLink className="navbar_btn" to={"/#about"} smooth>
									About
								</HashLink>
								<HashLink className="navbar_btn" to={"/#portfolio"} smooth>
									Portfolio
								</HashLink>
								<HashLink className="navbar_btn" to={"/#skills"} smooth>
									Skills
								</HashLink>
								<div className="icon_social_networks_menu">
									<div
										className="icon_git_hub"
										onClick={() => {
											window.open("https://github.com/kapcrew", "_blank");
										}}
									>
										{icon_git_hub}
									</div>
									<div
										className="icon_telegram"
										onClick={() => {
											window.open("https://t.me/ducktalesblock", "_blank");
										}}
									>
										{icon_telegram}
									</div>
								</div>
							</div>
						</div>
						<div className="for_main_btn_modal">
							<HashLink className="btn_сontact_us" to={"/#hereUs"} smooth>
								<div className="btn_сontact_us__text">Contact us</div>
								<div className="btn_сontact_us__fill">
									{border_btn_contact_us_active}
								</div>
								<div className="btn_сontact_us__border">
									{border_btn_contact_us}
								</div>
							</HashLink>
						</div>
					</motion.div>
				)}
			</AnimatePresence>
			<div className="header__main_btn">
				<HashLink className="btn_hire_us" to={"/#hereUs"} smooth>
					<div className="btn_hire_us__text">Hire us</div>
					<div className="btn_hire_us__fill">{btn_hire_us_fill}</div>
					<div className="btn_hire_us__border">{btn_hire_us}</div>
				</HashLink>

				{/* <div className="for_btn_language">
					<button className={"btn_language"}>
						EN{" "}
						<svg
							width="7"
							height="4"
							viewBox="0 0 7 4"
							fill="none"
							xmlns="http://www.w3.org/2000/svg">
							<path
								opacity="0.6"
								d="M1 0L3.5 2.5L6 0L7 0.5L3.5 4L0 0.5L1 0Z"
								fill="white" />
						</svg>
					</button>
					<div className="for_modal_language">
						<div className="modal_language">
							<button className="btn_language_choise">
								English
							</button>
							<button className="btn_language_choise">
								French
							</button>
						</div>
					</div>
				</div> */}
			</div>
		</div>
	);
};
